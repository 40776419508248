.sidebar__container {
    box-sizing: border-box;
    border-right: 1px solid #CCC;
    width: 25%;
    justify-content: flex-start;
    align-items: left;
    display: flex;
    flex-direction: column;
    background-color: #fff !important
}

.recipe-items__sidebar--content {
    margin-top: 32px;
}

.MuiSvgIcon-root.recipe-items__sidebar--icon {
    margin-right: 16px;
}
.MuiSvgIcon-root.gray-fill {
    fill: #767676
}
.MuiSvgIcon-root.black-fill {
    fill: #000
}
.recipe-items__sidebar--icon.recipe-items__sidebar--icon-add {
    margin: 0px !important;
    padding: 0px !important;

    &:hover {
        background-color: #c0c0c0 !important;
    }
}

.sidebar__container p:hover,
.recipe-items__sidebar--icon:hover,
.recipe-items__sidebar--user-folders:hover {
    cursor: pointer !important;
    background-color: #F8F8F5 !important;
}

.sidebar__container p:active,
.recipe-items__sidebar--user-folders:active {
    color: #767676 !important;
}

.recipe-items__sidebar--user-folders {
    padding: 4px !important;
    border-radius: 4px !important;
    display: flex; 
    align-items: center; 
    cursor: pointer; 
    box-sizing: border-box;
}

.recipe-items__sidebar--margin-right {
    margin-right: 8px !important;
    padding: 13px 9px 12px 8px !important;
    border-radius: 4px !important;
}

.recipe-items__sidebar-span--your-folders {
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
    display: flex;
    padding: 8px 0px;
    margin: 0px;
    &:hover {
        cursor: auto;
        background-color: inherit;
    }
}
.recipe-items__sidebar--lists-wrapper {
    margin-top: 8px;
    margin-right: 8px;
    margin-left: 10px;
}
.recipe-items__sidebar--content p {
    display: flex;
    padding: 8px;
    margin: 0px;
}

.span__new-folder {
    padding: 8px;
    margin: 0px;
}

.recipe-items__div-icon-span--new-folder {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 4px !important;
    border-radius: 4px;
    margin-right: 8px !important;
    margin-left: 10px;
    &:active {
        background-color: #F8F8F5;
    }
}

.icon--gray-border {
    border: 1px solid #CCC !important;
    padding: 0px !important;
    justify-content: center !important;
    align-items: center !important;
    vertical-align: middle !important;
    border-radius: 4px !important;
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    padding: 0 !important;
    display: flex !important;

    &:hover {
        background-color: #c0c0c0 !important;
    }
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #000;
}

@media screen and (max-width: 768px) {
    .sidebar__container {
        display: none;
    }
    .recipe-items__sidebar--content {
        margin-top: 50px;
    }
}
@media screen and (min-width: 768px) {
    .recipe-items__sidebar--content.mobile {
        display: none;
    }
    
}