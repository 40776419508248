.MuiGrid-root.recipe-grid__container {
    margin-top: 0px;
    margin: 0 auto;
    padding: 32px 10px;
    background-color: #FFF;
    flex-grow: 1;
    min-height: 5vh;
    justify-content: left;
    overflow-x: scroll;
    /* background-color: #f8f8f5; */
}

.padding-left-ten {
    padding-left: 10%;
}

.recipe-grid__subcontainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.recipe-grid__subheading-wrapper {
    margin-left: 0px;
}

@media screen and (max-width: 768px) {
    .MuiGrid-root.recipe-grid__container {
        width: 100vw;
        padding-top: 0px;
        background-color: #fff;
    }
}