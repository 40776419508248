.recipe-grid-container {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(165px, 1fr)); */
    gap: 16px;
}

.recipe-card {
    background-color: #FFF; /* Background color for cards */
    overflow: hidden; /* Ensure content inside card does not overflow */
    &:hover {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    }
}

@media (max-width: 768px) {
    .recipe-grid-container.flex {
        display: flex;
        height: 249px !important;
    }
    .recipe-grid-container {
        grid-template-columns: repeat(2, 1fr); /* Two cards per row on mobile */
    }
    .mt-175 {
        margin-top: 175px;
    }
    .mt-225 {
        margin-top: 245px;
    }
}

/* @media (min-width: 601px) {
    .recipe-grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
} */

@media (min-width: 900px) {
    .recipe-grid-container {
        grid-template-columns: repeat(4, 1fr); /* Adjust columns for larger screens */
    }
}
