.recipe-grid__subheading--wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7%;
    margin-top: 0px;
}

.MuiButtonBase-root.recipe-grid-subhheading__button-go {
    background-color: #df321b;
    border: 1px solid #df321b;
    border-radius: 25px;
    height: 50px;
    width: 100px;
    margin-left: 10px;
    display: none;
}

.MuiSvgIcon-root.recipe-grid__subheading--search-bar-cancel {
    margin-right: 10px;
}

.MuiSvgIcon-root.recipe-grid__subheading--search-bar-search {
    margin-left: 10px;
}

.recipe-grid__subheading--search-bar {
    margin: 0px;
}

.MuiButtonBase-root.recipe-grid__subheading__button-rename-folder {
    background-color: #df321b;
    border: 1px solid #df321b;
    border-radius: 5%;

    &:hover {
        background-color: #fff;
        color: #df321b !important;
        border: 1px solid #df321b;
    }
}

.recipe-grid__subheading--actions-first-row {
    margin-bottom: 20px;
}

.MuiInputBase-input.recipe-grid__subheading--dialog-textfield {
    padding: 1px;
}

.recipe-grid__subheading--recipe-folder-name {
    margin-left: inherit;
}

.recipe-grid__subheading--dialog-wrapper {
    border-bottom: 1px solid gray;
}

.MuiButtonBase-root.recipe-grid__subheading--button-cancel {
    color: gray;
}

.MuiTypography-root.recipe-grid__subheading--button-rename-folder {
    border-bottom: 1px solid #ccc;
    padding: 10px;
    font-family: inherit;

    &:hover {
        background-color: #c0c0c0;
    }
}

.MuiTypography-root.recipe-grid__subheading--button-delete-folder {
    padding: 10px;
    font-family: inherit;

    &:hover {
        background-color: #c0c0c0;
    }
}

.MuiSvgIcon-root.recipe-grid__subheading--icon-more {
    margin-left: 5px;
    margin-top: auto;
}

.recipe-grid__subheading--search-input {
    width: 300px;
    height: 50px;
    border: 1px solid #CCC;
    border-radius: 3px;
    background-color: #FFF;
    padding: none;
    margin: none;
    position: absolute;
    right: 2%;

    &:hover {
        border: 1px solid #000;
    }
}

.recipe-grid__subheading--search-input>input {
    width: 100%;
    height: 40px;
    padding: none;
    margin: none;
    border: none;
}

/* Styles for the input field */
.recipe-grid__subheading--search-input input {
    padding: 10px;
    width: 100%;
    /* Adjust as needed */
    box-sizing: border-box;
    /* Ensures padding doesn't affect the total width */
}

/* Removes border and outline when the input is focused */
.recipe-grid__subheading--search-input input:focus {
    border: none;
    /* Removes the border */
    outline: none;
    /* Removes the outline */
}

@media screen and (min-width: 360px) and (max-width: 374px) {
    .recipe-grid__subheading--search-input-wrapper {
        width: 82%;
    }
}
@media screen and (min-width: 375px) and (max-width: 400px) {
    .recipe-grid__subheading--search-input-wrapper {
        width: 86%;
    }
}
@media screen and (min-width: 401px) and (max-width: 415px) {
    .recipe-grid__subheading--search-input-wrapper {
        width: 95%;
    }
}

@media screen and (max-width: 768px) {
    .recipe-grid__subheading--wrapper {
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        margin-bottom: inherit;
        margin-top: 70px;
        position: absolute;
    }

    .recipe-grid__subheading--search-input {
        position: relative;
        right: 0;
        margin: 0px 0px 20px 0px;
    }

    .MuiButtonBase-root.recipe-grid-subhheading__button-go {
        display: block;
    }

    .hide-mobile-saved {
        display: none;
    }
}