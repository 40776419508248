/* --------------------------------------------------------------------------------
   Define the grid container with flexbox layout, enabling flexible and responsive design.
   It's set to display items in a row, wrapping as needed, with default alignment at the start.
-------------------------------------------------------------------------------- */
.grid {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

/* --------------------------------------------------------------------------------
   Base styling for grid columns, ensuring consistent padding and box-sizing.
   This setup allows for predictable layout adjustments and spacing between items.
-------------------------------------------------------------------------------- */
.grid-col {
  flex: 0 0 auto;
  padding: 0 0.5rem 0.5rem;
  box-sizing: border-box;
}

/* --------------------------------------------------------------------------------
   Specific column width configurations using flex-basis and max-width.
   These classes enable precise control over the column widths across the grid,
   allowing for a highly customizable and responsive grid layout.
-------------------------------------------------------------------------------- */
.grid-col_1 { flex-basis: 8.33333333%; max-width: 8.33333333%; }
.grid-col_2 { flex-basis: 16.66666667%; max-width: 16.66666667%; }
.grid-col_3 { flex-basis: 25%; max-width: 25%; }
.grid-col_4 { flex-basis: 33.33333333%; max-width: 66.66666667%; }
.grid-col_5 { flex-basis: 41.66666667%; max-width: 41.66666667%; }
.grid-col_6 { flex-basis: 50%; max-width: 50%; }
.grid-col_7 { flex-basis: 58.33333333%; max-width: 58.33333333%; }
.grid-col_8 { flex-basis: 66.66666667%; max-width: 66.66666667%; }
.grid-col_9 { flex-basis: 75%; max-width: 75%; }
.grid-col_10 { flex-basis: 83.33333333%; max-width: 83.33333333%; }
.grid-col_11 { flex-basis: 91.66666667%; max-width: 91.66666667%; }
.grid-col_12 { flex-basis: 100%; max-width: 100%; }

/* The series of .grid-col_ classes provide a scalable way to adjust the layout
   for different screen sizes and content needs, making the grid system versatile
   for a wide range of design scenarios. */