.recipe-photos__container {
    flex: 1;
    margin-right: 10%;
    align-self: flex-start;
}
.recipe-notes__subheader {
    margin-bottom: 6%;
}
.recipe-details__user-photos {
    gap: 16px;
    max-width: 1400px;
}
.recipe-details__user-photo {
    width: 250px;
    height: 250px;
}
.recipe-details__p--no-images {
    color: #888;
}
/* Add this class to change cursor to pointer when hovering over images */
.clickable-image {
    cursor: pointer;
}

/* Container for each image */
.image-container {
    position: relative;
    display: inline-block;
}

.recipe-details__user-photo {
    width: 250px;
    height: 250px;
    object-fit: cover;
    display: block;
}

/* Delete icon styling */
.delete-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    fill: white !important;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    padding: 10px;
    transition: opacity 0.3s;
}

.image-container:hover .delete-icon {
    opacity: 1;
}

.clickable-image {
    cursor: pointer;
}


/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it overlays other content */
}

/* Modal Content */
.modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
}

.modal-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
}

@media screen and (max-width: 768px) {
    .recipe-photos__container {
        margin: 0 10%;
        width: 80%;
    }
    .recipe-notes__subheader {
        text-align: left;
    }
    .recipe-details__user-photo {
        width: 150px;
        height: 150px;
    }
}