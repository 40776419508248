.recipe-items__container {
   margin: 0 auto;
   /* background-color: #f8f8f5; */
}

.recipe-items__body--grid-wrapper {
   width: 100%;
   padding-left: 3.5%;
   overflow: hidden;
   /* background-color: #F8F8F5; */
}
.recipe-items__container--see-all {
   display: flex;
   justify-content: center;
   margin-top: 3%;
}
.MuiButtonBase-root.recipe-items__button--back {
   justify-content: left;
   text-transform: none;
   color: inherit;
   margin-bottom: 12px;
}
.MuiButtonBase-root.recipe-items__button--see-all {
   text-transform: none;
   color: inherit;
   border: 1px solid black;
   width: 93%;
   border-radius: 20px;
   position: absolute;
   margin-top: 1%;
}

/* Centered container with max width for the recipe cards content */
.recipe-items__body--max-width {
   display: flex;
   /* Use flexbox layout */
   flex-direction: row;
   /* Arrange items in a row */
   justify-content: center;
   /* Center the items horizontally */
   max-width: 1200px;
   /* Max width of the container */
   margin: 0 auto;
   /* Center the container */
   position: relative;
   /* For positioning the pseudo-element */
}

/* Full-width color behind the max-width-container */
.recipe-items__body--max-width::before {
   content: '';
   /* Required for pseudo-elements */
   display: block;
   position: absolute;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   background-color: inherit;
   /* Use the same background as the parent */
   z-index: -1;
   /* Ensure it's behind the content */
}

.paper-background-bold {
   background-color: #F8F8F5;
}

.inherit-background {
   background-color: inherit;
   font-weight: normal;
}

.card-content {
   margin: 0px;
   height: 90px;
   overflow: auto;
}

.notes__button {
   display: flex;
}

.dialog__buttons {
   display: flex;
   flex-direction: column;
}

.gray-background:hover {
   background-color: #F8F8F5;
   cursor: pointer;
   justify-content: space-between;
}

.folder__photo {
   height: 40px;
   width: 40px;
   border-radius: 4px;

}

.recipe-items__sidebar--folder-name {
   cursor: pointer;
}

.recipe-items__sidebar--dialog-header {
   display: flex;
   justify-content: space-between;
   border-bottom: 1px solid gray;
}

.MuiTypography-root.recipe-items__sidebar--dialog-title {
   padding-bottom: 0px;
   margin-bottom: 0px;
}

.MuiButtonBase-root.recipe-items__sidebar--dialog-header-button {
   color: gray;
   text-transform: none;
   padding-bottom: 0px;
   margin-bottom: 0px;
}

.MuiInputBase-root.ecipe-items__sidebar--dialog-textfield {
   padding: 1px;
}

.recipe-items__sidebar--dialog-actions {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
}

.recipe-items__sidebar--dialog-actions {
   width: 100%;
   margin-bottom: 20px;
}

.MuiButtonBase-root.recipe-items__sidebar--button-create-new-folder {
   width: 50%;
   background-color: #df321b;
   color: white;
   border: 1px solid #df321b;
   border-radius: 5%;
   text-transform: none;

   &:hover {
      background-color: #df321b;
   }
}

.grocery-list-title {
   margin-right: 10px;
}

.grocery-list-count {
   margin-left: 10px;
}

.grocery-list-accordion {
   margin: 10px;
}

.grocery-list-summary {
   display: flex;
   align-items: center;
   width: 100%;
}

.MuiSvgIcon-root.grocery-list-summary-icon {
   cursor: pointer;
   margin-right: 8px;
}
.grocery-list__recipe-title {
   flex: 1;
}
.grocery-list__remove-button {
   cursor: pointer; 
   margin-left: 8px; 
   text-decoration: underline;
}
.grocery-list__clear-icon-container {
   display: flex;
   justify-content: space-between;
   border-bottom: 1px solid #d3d3d3;
}
.grocery-list__ingredient,
.MuiSvgIcon-root.grocery-list__clear-icon {
   padding: 10px 0;
}

.search {
   width: 24px !important;
   padding: 0% !important;
   margin: 0% !important;
}

/* For popover on recipes */
.recipe-grid__popover {
   margin: 5px;
   padding: 0;
   list-style-type: none;
}

.recipe-grid__popover-button:hover {
   background-color: rgb(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
   .recipe-items__body--max-width {
      flex-direction: column-reverse;
   }
   .recipe-items__container {
      margin-top: 0%;
   }

   .recipe-items__body--grid-wrapper {
      padding-left: 0%;
      background-color: #fff;
   }
}
@media screen and (min-width: 768px) {
   .MuiButtonBase-root.recipe-items__button--back {
      display: none;
   }
   .recipe-items__container--see-all {
      display: none;
   }
}