ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    color: #000 !important;
}

.dropdown-item {
    color: #000 !important;
}

.header__img--mobile {
    width: 25%;
    height: 90%;
    cursor: pointer;
    border-radius: 75%;
}

.MuiSvgIcon-root.mobile__header-icon {
    padding: 10px 5px;
}

.mobile-header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    /* Adjust as necessary */
    z-index: 1000;
    /* Ensure it's above other elements */
    border-bottom: 1px solid #d3d3d3;
    height: 50px;
    align-items: center;
    display: inherit;
}

.mobile-header__wrapper {
    margin-top: 5px;
}
.mobile-header__icon-wrapper {
    width: 35%;
    height: 40px;
}


@media screen and (min-width: 768px) {
    .mobile-header {
        display: none;
    }
}