.user-pantry__wrapper {
    padding-top: 0px;
    margin: 20px auto;
}

.user-pantry__subheading {
    margin-bottom: 10px;
}

.user-pantry__section-heading {
    font-size: 1.2rem;
}

.MuiPaper-root.pantry-accordion {
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
    .user-pantry__wrapper {
        margin-top: 50px;
    }
}