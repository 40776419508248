.formPanel {
    max-width: 400px;
    margin: auto;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    box-sizing: border-box; /* Ensure padding and border are included in width */
  }
  
  .formTitle {
    margin-bottom: 1rem;
    text-align: center;
    color: #333;
  }
  
  .formGroup {
    margin-bottom: 1rem;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .formInput {
    width: 100%;
    max-width: 100%; /* Ensure it does not exceed parent width */
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box; /* Include padding and border in total width */
  }
  
  .formButton {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #288c6e;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    box-sizing: border-box; /* Include padding and border in total width */
  }
  
  .formButton:hover {
    background-color: #23755a;
  }
  
  .alert {
    color: #d9534f;
    margin-bottom: 1rem;
    font-size: 0.875rem;
  }
  